import { atom } from 'jotai'
import textIta from '../translations/ita.json'
import { IGenericObject } from 'types'

export enum siteLanguage {
    ITA = 'ITA',
    ENG = 'ENG'
}

interface ILanguageSettings {
    language: siteLanguage.ITA | siteLanguage.ENG
    translation: IGenericObject<IGenericObject<string>>
}

export const langSettings = atom<ILanguageSettings>({
    language: siteLanguage.ITA,
    translation: textIta
})