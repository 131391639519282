import React, { CSSProperties } from 'react'
import './Section.css'

type Props = {
    children: React.ReactNode
    sectionId: string
    backgroundColor?: string
    title?: string
    className?: string
    style?: CSSProperties
}

function Section({backgroundColor, className, children, sectionId, style, title}: Props) {
  return (
    <section id={sectionId} className={className} style={{ ...style, backgroundColor: backgroundColor || 'white' }}>

        {title && <div className='section-title margin-bottom-20'>{title}</div>}

        {children}
    
    </section>
  )
}

export default Section