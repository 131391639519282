import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { isMobileDevice } from 'utils';
import { useAtom } from 'jotai';
import { siteLanguage, langSettings } from 'state';
import textIta from '../../translations/ita.json'
import textEng from '../../translations/eng.json'
import logo from 'assets/img/cafedeanatolia.png'
import flagIta from 'assets/img/italy-flag.png'
import flagEng from 'assets/img/uk-flag.png'
import './Header.css'

interface IMenuItems {
  label: string
  path: string
}

function Header() {
  const navigate = useNavigate();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false)

  const [Language, setLanguage] = useAtom(langSettings)
  const { header } = Language.translation;

  const menuItems: IMenuItems[] = [
    { label: header.narghile, path: '/narghile' },
    { label: header.events, path: '/eventi' },
    { label: header.catering, path: '/catering' }
  ]

  const renderLogo = () => <img className="brand cursor-pointer" src={logo} width="auto" height="auto" alt="Cafe de anatolia" onClick={() => navigate('/')} />

  const renderFlags = () => (
    <>
      <img
        className='cursor-pointer'
        src={flagIta}
        height={"32px"}
        width={"auto"}
        alt="Italiano"
        title="Italiano"
        onClick={() => {
          setLanguage({ language: siteLanguage.ITA, translation: textIta })
          if(isMobileMenuOpen) setIsMobileMenuOpen(false)
        }}
      />
      <img
        className='cursor-pointer'
        src={flagEng}
        height={"32px"}
        width={"auto"}
        alt="English"
        title="English"
        onClick={() => {
          setLanguage({ language: siteLanguage.ENG, translation: textEng })
          if(isMobileMenuOpen) setIsMobileMenuOpen(false)
        }}
      />
    </>
  )

  return (
    <header>

      {
        !isMobileDevice() &&
        <div className='header-desktop'>
          {renderLogo()}
          {
            React.Children.toArray(menuItems.map((el: IMenuItems) => (
              <div
                className='menu-items'
                onClick={() => navigate(el.path)}>
                {el.label}
              </div>
            )))
          }
          <div className='flags-container'>
            {renderFlags()}
          </div>
        </div>
      }

      {
        isMobileDevice() &&
        <div className='header-mobile'>
          <div className='mobile-logo-container'>
            {renderLogo()}
            <i className={`fa-solid fa-${isMobileMenuOpen ? 'xmark' : 'bars'} font-24`} onClick={() => setIsMobileMenuOpen(prevState => !prevState)} />
          </div>

          {isMobileMenuOpen &&
            <div className='mobile-menu-items'>
              {
                React.Children.toArray(menuItems.map((el: IMenuItems) => (
                  <div
                    className='menu-items'
                    onClick={() => {
                      setIsMobileMenuOpen(false)
                      navigate(el.path)
                    }}>
                    {el.label}
                  </div>
                )))
              }

              <div style={{ display: "flex", columnGap: "10px" }}>{renderFlags()}</div>
            </div>
          }
        </div>
      }

    </header>
  )
}

export default Header