import React from 'react'
import Banner from './subsections/Banner'
import HomeContent from './subsections/HomeContent'
import './Home.css'

function Home() {
  return (
    <>
      <Banner />
      <div style={{ height: "3px", width: "100%", backgroundColor: "var(--anatolia-gold)" }} />
      <HomeContent />
    </>
  )
}

export default Home