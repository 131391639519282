import { useNavigate } from "react-router-dom";
import Card from 'components/Card'
import Section from 'components/Section'
import parse from 'html-react-parser'
import { useAtom } from "jotai";
import { langSettings } from "state";
import BannerImg from 'assets/img/banner.jpg'
import EventsImg from 'assets/img/cafeanatolia_events.png'
import Catering from 'assets/img/cafeanatolia_catering.png'

function HomeContent() {
    const navigate = useNavigate()

    const [Language] = useAtom(langSettings)
    const { 
        header: {narghile, events, catering},
        homeContent: { presentation, cardNarghile, cardEventi, cardCatering, cardButtons } 
    } = Language.translation;

    return (
        <Section sectionId="home-content">
            <div className='home-presentation paper-background'>
                {parse(presentation)}
            </div>

            <div className='servizi-container'>
                <Card
                    className={"margin-10"}
                    cardTitle={narghile}
                    cardImage={BannerImg}
                    cardDescription={"Narghilè"}
                    cardBody={cardNarghile}
                    cardAction={() => navigate('/narghile')}
                    cardActionLabel={cardButtons}
                />

                <Card
                    className={"margin-10"}
                    cardTitle={events}
                    cardImage={EventsImg}
                    cardDescription={"Eventi"}
                    cardBody={cardEventi}
                    cardAction={() => navigate('/eventi')}
                    cardActionLabel={cardButtons}
                />

                <Card
                    className={"margin-10"}
                    cardTitle={catering}
                    cardImage={Catering}
                    cardDescription={"Catering"}
                    cardBody={cardCatering}
                    cardAction={() => navigate('/catering')}
                    cardActionLabel={cardButtons}
                />
            </div>
        </Section>
    )
}

export default HomeContent