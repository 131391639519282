import React from 'react'
import Card from 'components/Card'
import { useAtom } from 'jotai'
import { langSettings } from 'state'
import { Helmet } from 'react-helmet'
import './Narghile.css'

// Image imports
import alphaHookah from 'assets/img/shisha/alpha_hookah.jpeg'
import apocalypse from 'assets/img/shisha/apocalypse.jpeg'
import apocalypseRocket from 'assets/img/shisha/apocalypse_rocket.jpeg'
import apocalypseTommyBlue from 'assets/img/shisha/apocalypse_tommy_gun_blue.jpeg'
import apocalypseTommyRed from 'assets/img/shisha/apocalypse_tommy_gun_red.jpeg'
import apocalypseKremlinBlue from 'assets/img/shisha/apocalyse_kremlin_blue.jpeg'
import apocalypseKremlinRed from 'assets/img/shisha/apocalyse_kremlin_red.jpeg'
import bladeHookah from 'assets/img/shisha/blade_hookah.jpeg'
import bladeGreen from 'assets/img/shisha/blade_green.jpeg'
import bladeRed from 'assets/img/shisha/blade_red.jpeg'
import koress from 'assets/img/shisha/koress_k3.jpeg'
import mobKalash from 'assets/img/shisha/mob_kalash.jpeg'
import mozeVyro from 'assets/img/shisha/moze_vyro_black.jpeg'
import oduman from 'assets/img/shisha/oduman.jpeg'


interface IHookah {
  img: string
  label: string
}

function Narghile() {

  const [Language] = useAtom(langSettings)
  const { narghile: { title } } = Language.translation;

  const hookahList: IHookah[] = [
    { img: alphaHookah, label: "Alpha Hookah" },
    { img: apocalypse, label: "Apocalypse Hookah" },
    { img: apocalypseRocket, label: "Apocalypse Rocket" },
    { img: apocalypseTommyBlue, label: "Apocalypse Tommy Gun - Blue" },
    { img: apocalypseTommyRed, label: "Apocalypse Tommy Gun - Red" },
    { img: apocalypseKremlinBlue, label: "Apocalypse Kremlin - Blue" },
    { img: apocalypseKremlinRed, label: "Apocalypse Kremlin - Red" },
    { img: bladeHookah, label: "Blade Hookahs" },
    { img: bladeGreen, label: "Blade Hookah - Green" },
    { img: bladeRed, label: "Blade Hookah - Red" },
    { img: koress, label: "Koress K3" },
    { img: mobKalash, label: "Mob Kalashnikov" },
    { img: mozeVyro, label: "Moze Vyro - Black" },
    { img: oduman, label: "Oduman" }
  ]

  return (
    <div className='pages-container narghile-container'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Narghile</title>
      </Helmet>

      <h2 className='text-center margin-bottom-30'><u>{title}</u></h2>

      <div className="narghile-cards-container">
        {
          React.Children.toArray(hookahList.map((el: IHookah) => (
            <Card
              className={"margin-10"}
              cardImage={el.img}
              cardDescription={el.label}
              cardBody={el.label}
            />
          )))
        }
      </div>

    </div>
  )
}

export default Narghile