import React, { useLayoutEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Footer from 'components/Footer'
import Header from 'components/Header'
import Home from 'pages/Home'
import Narghile from 'pages/Narghile'
import Catering from 'pages/Catering'
import Eventi from 'pages/Eventi'

function App() {

  // Metodo per aggiungere lo scroll to top ad ogni cambio di pagina
  const Wrapper = ({ children }: any) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  }

  return (
    <div className='app-wrapper'>
      <Wrapper>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/narghile' element={<Narghile />} />
          <Route path='/catering' element={<Catering />} />
          <Route path='/eventi' element={<Eventi />} />
          <Route path='*' element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Wrapper>
    </div>
  );
}

export default App;
