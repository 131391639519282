import React from 'react'
import Section from 'components/Section'
import parse from 'html-react-parser'
import { useAtom } from 'jotai';
import { langSettings } from 'state';
import BannerImg from 'assets/img/banner.jpg'

function Banner() {

  // Import language atom and destructuring translations
  const [Language] = useAtom(langSettings)
  const {homeContent} = Language.translation

  return (
    <Section
      sectionId="banner"
      className="banner-container"
      style={{ backgroundImage: `url(${BannerImg})` }}
    >
      <div className='banner-content'>
        {parse(homeContent.banner)}
      </div>
    </Section>
  )
}

export default Banner