import React from 'react'
import { useAtom } from 'jotai';
import { langSettings } from 'state';
import './Footer.css'

function Footer() {

  // Import language atom and destructuring translations
  const [Language] = useAtom(langSettings)
  const {footer} = Language.translation

  return (
    <footer>
      <div className='footer-sections-container'>

        <div className='footer-sections' style={{ justifyContent: "center" }}>
          <h3>{footer.follow}</h3>

          <div>
            <i
              className="fa-brands fa-instagram-square cursor-pointer font-24 margin-5"
              title="Instagram"
              onClick={() => window.open('https://www.instagram.com/cafedeanatoliaroma/?hl=it', 'blank')}
            />

            <i
              className="fa-brands fa-facebook-square cursor-pointer font-24 margin-5"
              title="Facebook"
              onClick={() => window.open('https://it-it.facebook.com/cafedeanatolia132', 'blank')}
            />

            <i
              className="fa-brands fa-whatsapp-square cursor-pointer font-24 margin-5"
              title="Whatsapp"
              onClick={() => window.open('https://wa.me/+393894209806', 'blank')}
            />
          </div>

          <h3 className='margin-top-30'>{footer.contacts}</h3>
          <div>
            <div>Via Merulana, 132/133</div>
            <div>00185 Roma RM</div>
            <div>
              Tel:&nbsp;
              <a href="tel:+393894209806" className='cursor-pointer' style={{ textDecoration: "none", color: "var(--anatolia-gold)" }}>
                +39 389 4209806
              </a>
            </div>
          </div>
        </div>

        <div className='footer-sections'>
          <iframe
            title="Cafe de Anatolia"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11880.930385002841!2d12.5044485!3d41.8878546!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc5c15a542bba56d2!2sShisha%20lounge%20CafedeAnatolia!5e0!3m2!1sit!2sit!4v1658242460460!5m2!1sit!2sit"
            style={{ border: 0 }}
            loading="lazy"
          />
        </div>

      </div>

      <div className="footer-disclaimer">
        {`@Copyright ${new Date().getFullYear()} - Cafe' De Anatolia S.r.l. - Via Merulana, 132/133 Roma - P. IVA 15103411003`}
      </div>
    </footer>
  )
}

export default Footer