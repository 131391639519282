import { MouseEventHandler } from 'react'
import './Card.css'

type Props = {
    cardImage: string
    cardDescription: string
    cardTitle?: JSX.Element | string
    cardBody: string
    className?: string
    cardAction?: MouseEventHandler<HTMLButtonElement>
    cardActionLabel?: string
}

function Card({ cardAction, cardActionLabel, cardDescription, cardImage, cardTitle, cardBody, className }: Props) {
    return (
        <div className={`card-container border-radius-5 ${className}`}>
            <img src={cardImage} width="100%" height="auto" alt={cardDescription} />

            {
                cardTitle && <div className='card-title'>{cardTitle}</div>
            }

            <div className='card-body'>{cardBody}</div>

            {
                cardAction && <button className="card-button border-radius-4" onClick={cardAction}>{cardActionLabel}</button>
            }
        </div>
    )
}

export default Card