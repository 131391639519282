import { useAtom } from 'jotai';
import { Helmet } from 'react-helmet'
import parse from 'html-react-parser'
import { langSettings } from 'state';
import './Eventi.css'

function Eventi() {

  const [Language] = useAtom(langSettings)
  const { events: { description } } = Language.translation;

  return (
    <div className='pages-container events-container paper-background'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Eventi</title>
      </Helmet>

      {parse(description)}
    </div>
  )
}

export default Eventi