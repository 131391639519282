import { useAtom } from "jotai";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import { langSettings } from "state";
import "./Catering.css";

function Catering() {
  const [Language] = useAtom(langSettings);
  const {
    catering: { description },
  } = Language.translation;

  return (
    <div className="pages-container catering-container paper-background">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Catering</title>
      </Helmet>

      {parse(description)}
    </div>
  );
}

export default Catering;
